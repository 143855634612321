.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #eee !important;
    color: #000 !important;
    border-radius: 0 !important;
}

.InputFromTo-to .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #eee !important;
    color: #000 !important;
    border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
}

.InputFromTo-to .DayPicker-Day {
    border-radius: 0 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #000;
    color: #fff !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #000 !important;
}

.DayPicker-Day--today {
    color: #000 !important;
    font-weight: 700;
}

.InputFromTo .DayPickerInput-Overlay {
    z-index: 900;
}

.InputFromTo-to .DayPickerInput-Overlay {
    z-index: 900;
}

.InputFromTo input {
    width: 200px !important;
}

.InputFromTo-to input {
    width: 200px !important;
}

.select-room {
    z-index: 100;
    width: 268px;
}