/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
/* Portrait */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    #mazemap-container, #mazemap-root {
        width: 290px;
        height: 270px;
    }

    #info-container {
        width: 290px;
    }

    h1 {
        font-size: 1.5rem !important;
    }

    table, .s-button, .breadcrumb, .no-outline {
        font-size: .80rem !important;
    }

    .popup-content {
        margin: 0 0 0 10px !important;
    }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    #mazemap-container, #mazemap-root {
        width: 345px;
        height: 280px;
    }

    #info-container {
        width: 345px;
    }

    h1 {
        font-size: 1.5rem !important;
    }

    table, .s-button, .breadcrumb, .no-outline {
        font-size: .80rem !important;
    }

    .popup-content {
        margin: 0 0 0 10px !important;
    }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Portrait */
@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) {
    #mazemap-container, #mazemap-root {
        width: 385px;
        height: 290px;
    }

    #info-container {
        width: 385px;
    }

    h1 {
        font-size: 1.5rem !important;
    }

    table, .s-button, .breadcrumb, .no-outline {
        font-size: .80rem !important;
    }

    .popup-content {
        margin: 0 0 0 10px !important;
    }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 1) {
    #mazemap-container, #mazemap-root {
        width: 700px;
        height: 512px;
    }

    #info-container {
        width: 700px;
    }

    .InputFromTo input, .InputFromTo-to input {
        width: 165px !important;
    }

    .select-room {
        z-index: 100;
        width: 220px;
    }
}

/* ----------- Desktop Screens ----------- */
@media only screen and (min-width: 1281px) {
    #mazemap-container, #mazemap-root {
        width: 839px;
        height: 512px;
    }

    #info-container {
        width: 839px;
    }
}

#mazemap-container, #info-container {
    min-height: 260px;
}

#mazemap-root {
    border: 1px solid #dee2e6;
}

.mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.mazemap-control-zlevel-bar button.z-label.active {
    background: #000;
    color: #fff !important;
}

.mazemap-control-zlevel-bar button.z-label:hover {
    background: #ebebeb !important;
    color: #000 !important;
}

.mazemap-control-zlevel-bar .btns-container > button {
    color: #000 !important;
}

#info-container {
    font-size: smaller;
}

#room-details {
    margin-bottom: 10px;
}

#qr-code {
    margin-top: 10px;
}

#qr-code-img {
    width: 60px;
    height: 60px;
}

#freebusy-container {
    width: 100%;
    margin-bottom: 5px;
    font-size: small;
}

.no-wrap {
    white-space: nowrap;
    width: 100px;
}

.free-info {
    width: 10px;
    height: 10px;
    background: #b1dfbb;
    display: inline-block;
    margin-right: 5px;
}

.semi-free-info {
    width: 10px;
    height: 10px;
    background: #ffc107;
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
}

.busy-info {
    width: 10px;
    height: 10px;
    background: #e2b8b8;
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
}

.layout-main {
    margin-top: 0;
    padding-top: 0;
}

.nowrap {
    white-space: nowrap;
}

.popup-overlay {
    background: rgba(255, 255, 255, 0) !important;
}

.inner {
    width: 500px;
}

.popup-content {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
    background: rgba(255, 255, 255, 1) !important;
    z-index: 200;
}

.no-outline {
    box-shadow: none !important;
    outline: none !important;
}

.active-sort {
    font-weight: bold;
}

#scroll-button:hover {
    opacity: 1 !important;
    border: none !important;
}

#scroll-button svg {
    fill: #fff;
}

.mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl a {
    pointer-events: none !important;
    cursor: default;
    opacity: 0.6;
}

@media screen and (min-width: 767px) {
    #navigation {
        display: block;
    }

    .popup-content {
        margin: 122px 0 0 0 !important;
        display: flex;
        justify-content: center;
    }
}